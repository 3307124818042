import React from 'react'
import Uppy from '@uppy/core'
import { DragDrop, ProgressBar } from '@uppy/react'
import AwsS3 from '@uppy/aws-s3'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

export default class extends React.Component {
  constructor(props) {
    super(props)

    const uppy = Uppy({
      meta: { type: 'file' },
      autoProceed: true
    })

    uppy.use(AwsS3, {
      getUploadParameters (file) {
        // Send a request to our PHP signing endpoint.
        return fetch('/.netlify/functions/sign', {
          method: 'post',
          // Send and receive JSON.
          headers: {
            accept: 'application/json',
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            filename: file.name,
            contentType: file.type
          })
        }).then((response) => {
          // Parse the JSON response.
          return response.json()
        }).then((data) => {
          console.log(data)
          // Return an object in the correct shape.
          return {
            method: 'PUT',
            url: data.uploadUrl,
            fields: [],
            headers: []
          }
        })
      }
    })

    uppy.on('complete', result => {
      const urls = result.successful.map(s3File => s3File.uploadURL)
      this.props.onUpload(urls)
    })

    this.uppy = uppy
  }
  render() {
    return (
      <div>
        <DragDrop
          uppy={this.uppy}
          locale={{
            strings: {
              dropHereOr: 'Drop here or %{browse}',
              browse: 'browse'
            }
          }}
        />
        <ProgressBar uppy={this.uppy} />
      </div>
    )
  }
}
