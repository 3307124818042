import React from 'react'
import './App.css'

import Uploader from './Uploader'
import Image from './Image'

export default () => {
  const [images, setImages] = React.useState([])
  const onUpload = (resultUrls) => {
    const resizeUrlPromises = resultUrls.map(url => {
      const extension = (url.match(/\.(\w+)$/)[1] || 'jpg').toLowerCase()
      return fetch(`/.netlify/functions/resize?url=${encodeURIComponent(url)}&extension=${extension}`)
        .then(response => response.json())
        .then(json => json.resultUrl)
    })

    Promise.all(resizeUrlPromises).then(urls => {
      const result = urls.map(url => ({ url }))
      setImages(result)
    })
  }

  return (
    <div className="App">
      <Uploader onUpload={onUpload}/>
      {
          images.length > 0
            ? <div className="results">
                { images.map(image => <Image {...image} />) }
              </div>
            : null
        }
    </div>
  )
}
